.container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -100px);
    width: 600px;
    height: 400px;

}

.championImage {
    width: 100%;
}
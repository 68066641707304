.container {
    position: absolute;
    left: 40px;
    bottom: 18px;
    width: 200px;
    height: 200px;
    background: rgba(0,0,0,0.55);
    border-radius: 16px;
    pointer-events: none;
}

.playerPos {
     position: absolute;
     left: 0px;
     top: 0px;
     background: #00FFE0;
     width: 6px;
     height: 6px;
     transform: rotate(45deg);
}
@import url('https://fonts.googleapis.com/css2?family=Jersey+10&display=swap');
body {
  margin: 0;
  font-family: "Jersey 10", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  text-shadow: inherit;
}

h1,h2,h3,h4,h5,h6 {
   margin:0;
   padding:0;
}
p {
  margin-block-start:0; margin-block-end:0; margin-inline-start:0; margin-inline-end:0;
padding-block-start:0; padding-block-end:0; padding-inline-start:0; padding-inline-end:0;
}
.container{
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-family: "Jersey 10";
    color: white;
    text-shadow: 0px 0px 4px black, 0px 0px 4px black,0px 0px 4px black;
    font-size: 32px;
}
.menuUIFlexBox{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding-inline: 24px;
    padding-block:24px;

}
.errorLabel {
    color: #e97777;
    margin-bottom: 8px;
    margin-top: -8px;
    font-family: "Roboto";
    font-size: 16px;
}
.guestNameInput {
    background: rgba(0,0,0,0.5);
    width: 300px;
    height:80px;
    margin-bottom: 24px;
    border-radius: 4px;
    font-family: 'Jersey 10';
    text-align: center;
    font-size: 28px;
    color: #ffad00;
}
.guestNameInput::placeholder {
    font-size: 24px;
    color:rgb(156, 156, 156);
}
.guestPlayButton {
    background:linear-gradient(rgba(251,253,255,1.0), rgba(175,183,188,0.6));
    box-shadow: 0px 4px 4px 0px black, 0px 4px 10px 4px #E7EDE2;
    border-radius: 4px;
    padding-inline: 12px;
    padding-block: 8px;
    width: 240px;
    margin-bottom: 64px;
}
.authButtonsFlex{
    display: flex;
    gap:32px;
}
.authButton{
    background:linear-gradient(#BE9F5E, #644A1A);
    box-shadow: 0px 4px 4px 0px black, 0px 4px 10px 1px #d78710;
    border-radius: 4px;
    padding-inline: 12px;
    padding-block: 8px;
    /* background: red; */
}
.container{
    position: absolute;
    left: 32px;
    top: 32px;
}

.socialsFlexbox{
    height: 100%;
    display: flex;
    justify-content: space-between;
    gap: 16px;

}
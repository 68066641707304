
.container{
    position: absolute;
    left:0%;
    top: 1%;
    background: radial-gradient(rgba(0,0,0,0.9),rgba(0,0,0,0) 60%, rgba(0,0,0,0));
    width:350px;
    height:100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:50%
    
}
.labelText{
    letter-spacing: 6px;
    font-size: 32px;
    text-shadow: 0px 0px 4px black, 0px 0px 4px black,0px 0px 4px black;
    color:#ffffff;
}
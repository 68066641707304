
.container{
    background: rgba(0,0,0,0.4);
    width: 100%;
    height: 100%;
    position: absolute;


    display: flex;
    justify-content: center;
    align-items: center;
    
    font-family: "Jersey 10";
    color: white;
    text-shadow: 0px 0px 4px black, 0px 0px 4px black,0px 0px 4px black;
    font-size: 32px;
}
.flex {
    display:flex;
    flex-direction: column;
    align-items: center;

}
/* =============== */
/* HERO WHEEL CSS */
/* =============== */
.championSelectContainer {
    position: relative;
    width: 600px;
    height: 400px;
    /* background: rgba(0,0,0,0.5); */
}
.championAvatarButton {
    position: absolute;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    box-shadow: 0px 0px 2px 4px rgba(255,255,255,0.7);
    overflow:hidden;
}
.unSelectedChampionHover:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 4px 8px rgba(255, 238, 0, 0.7);
}
.selectedChampionGlow {
    box-shadow: 0px 0px 4px 8px rgba(255, 238, 0, 0.7);
}
.avatar1 {
    left: calc(50% - 60px);
    top: 10%;
    /* transform: translateX(-50%); */
}
.avatar2 {
    left: calc(70% - 60px);
    top: 28%;
    /* transform: translateX(-50%); */
}
.avatar3 {
    left: calc(61% - 60px);
    top: calc(97% - 144px);
    /* transform: translateX(-50%) translateY(-120%); */
}
.avatar4 {
    left: calc(39% - 60px);
    top: calc(97% - 144px);
    /* transform: translateX(-50%) translateY(-120%); */
}
.avatar5 {
    left: calc(30% - 60px);
    top: 28%;
    /* transform: translateX(-50%); */
}
.lockOverlay {
    position:absolute;
    left: 0%; top: 0%;
    transform: translateX(12.5%) translateY(12.5%);
    width: 96px;
    height: 96px;
    background-color: rgba(0,0,0,0.2);
    background-image: url('../../../assets/lock.png');
    background-size:90% 90%;
    background-position: center;
    opacity: 0.8;
    border-radius: 50%;
}
.championImage {
    width: 120px;
    height: 120px;
}
@keyframes growAnimation {
    0% {
        transform: scale(1.0);
    }
    100% {
        transform: scale(1.2);
    }
}
.championText {
    position: absolute;
    left: calc(50% );
    top: calc(50%);
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    color: #a83ec9;
    z-index: 1000;
    background: rgba(0,0,0,0.3);
    border-radius: 50%;
}
.pickAChampionText {
    position: absolute;
    left: calc(50% - 48px);
    top: calc(50% - 32px);
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    color: #a83ec9;
    animation: growAnimation 1.8s ease-in-out infinite alternate;
    z-index: 1000;
}
/* ================== */
/* HERO WHEEL CSS END */
/* ================== */


.wheelUIContainer {
    display:flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}
.wheelButton1Container {
    position: relative;
    filter: drop-shadow(-3px 3px 1px rgb(0, 0, 0, 0.5))
}
.wheelButton1 {
    width: 64px;
    height: 64px;
    background: #816E6E;
    clip-path: polygon(100% 0,100% 100%,0 50%);
}
.smallWheel1 {
    position: absolute;
    top: 0; left: 0;
    width: 64px;
    height: 64px;
    
    background: linear-gradient(-90deg, rgb(226, 226, 226) 0, #7e7e7e 100%);
    clip-path: polygon(96% 6%,96% 94%,6% 50%);
}
.wheelButton2Container {
    position: relative;
    filter: drop-shadow(3px 3px 1px rgb(0, 0, 0, 0.5))
}
.wheelButton2 {
    width: 64px;
    height: 64px;
    background: #816E6E;
    clip-path: polygon(0 0,100% 50%,0 100%);
}
.smallWheel2 {
    position: absolute;
    top: 0; left: 0;
    width: 64px;
    height: 64px;
    background: linear-gradient(90deg, rgb(226, 226, 226) 0, #7e7e7e 100%);
    clip-path: polygon(4% 4%,94% 50%,4% 96%);
}
.championName {
    color: #7BB5CC;
    letter-spacing: 0.1em;
    text-align: center;
    /* Name was leaning to the left for some reason */
    margin-left:8px;
    border-bottom: #aab7c4c7 1px solid;
}



/* BUTTON CSS */
.championSelectButton {
    background:linear-gradient(rgba(251,253,255,1.0), rgba(175,183,188,0.6));
    box-shadow: 0px 4px 4px 0px black, 0px 4px 10px 4px #E7EDE2;
    border-radius: 8px;
    padding-inline: 12px;
    padding-block: 8px;
    width: 280px;
    height: 80px;
    margin-bottom: 32px;
    font-size: 40px;
}
.leaderboardButton {
    position: relative;
    background:linear-gradient(rgba(251,253,255,1.0), rgba(175,183,188,0.6));
    box-shadow: 0px 4px 4px 0px black, 0px 4px 10px 4px #E7EDE2;
    border-radius: 8px;
    padding-inline: 12px;
    padding-block: 8px;
    width: 152px;
    margin-bottom: 32px;
    font-size: 24px;
}
.leaderboardButton:hover {
    cursor: not-allowed;
}
.leaderboardButton::after {
    position: absolute;
    top:-40%; left: 80%;
    content: "Coming soon!";
    color: white;
    background: rgba(0, 162, 255, 0.5);
    width: 120px;
    border-radius: 50%;
    padding: 4px;
}

.backButton {
    background: #7FA9D3;
    box-shadow: 0px 4px 4px 0px black, 0px 4px 10px 4px #7FA9D3;
    
    border-radius: 4px;
    padding-inline: 12px;
    padding-block: 8px;
    width: 88px;
    font-size: 24px;

    display:flex;
    gap: 8px;
}

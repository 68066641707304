@import url('https://fonts.googleapis.com/css2?family=New+Rocker&display=swap');

.container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/loginBackground.png');
    background-size: 103% 103%;
    background-position: center, top, bottom;
    background-repeat: no-repeat;
}
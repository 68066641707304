
.container {
    position: absolute;
    right: 0px;
    top: 16px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 16px;
}
@import url('https://fonts.googleapis.com/css2?family=New+Rocker&display=swap');

.container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: lightblue;
    background-image: url('../../assets/loginBackground.png');
    background-size: 110% 110%;
    background-position: center, top, bottom;
    background-repeat: no-repeat;
}
.overlay {
    position: absolute;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,0.2);
}
.container{
    display: flex;
    width: 100%;
    height: 248px;
    background-color: rgba(0,0,0,0.5);
    border-radius: 8px;
    color: #DFFCFF;
    font-size: 24px;
    font-weight: 800;
    font-family: Cousine;
    justify-content: center;
    align-items: center;
    padding-inline: 16px;
    margin-top: 16px;

}

.bioFlexbox {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 24px;
}
.championImage {
    width: 196px;
    height: 196px;
    flex: 4;
    border-radius: 4px;
    border: 2px solid white;
}
.championBio {
    flex: 7;
    width: 100%;
}
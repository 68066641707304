.container {
    position: absolute;
    left: 50%;
    bottom: 18px;
    background:rgba(0, 0, 0, 0.3);
    width:400px;
    height:35px;
    border: 3px #303030 solid;
    border-radius: 6px;
    transform: translateX(-50%);
    box-shadow: inset 0px 8px 8px -2px rgba(255, 255, 255, 0.55);
    pointer-events: none;
}

.gloss{
    position: absolute;
    left:0;
    top:0;
    width: 0%;
    height:100%;
    background: #F0D96C;
    border-radius: 4px;
    box-shadow: inset 0px 8px 8px -2px rgba(255, 255, 255, 0.55), 2px 0px 4px 0px rgba(0, 0, 0, 0.55);
}

.label {
    height: 100%;
    display: flex;
    align-items: center; 
    position: relative;
    left: -30px;
    top: 0;
    color: black;
}
.meter {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    color: black;
}
.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    color: #DFFCFF;
    font-size: 12px;
    font-family: Cousine;
    justify-content: flex-start;
    padding-inline: 8px;
    padding-bottom: 16px;

}

.resultName {
    color: orange;
    color: #C3C02A;
}

.headerName {
    color: #C3C02A;
    color: orange;
    font-weight: 800;
    margin-top: 8px;
    margin-bottom:2px;
    font-size: 12.3px;
}
.headerDescription {
    font-weight: 400;
    height: 24px;
}

.abilityBar {
    display: flex; 
    margin-top: 8px;
}
.abilityImage {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
}
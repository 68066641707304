.container{
    font-family: Cousine;
}

.UIContainer {
    /* Display */
    display: flex;
    flex-direction: column;
    width:500px;
    height: 200px;
    /* Position */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* Flex Settings */
    gap: 15px;
    /* Work in Progress */
    /* background: red; */
}


/* Game Status Bar */
.statusContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-end;
    /* Work in Progress */
    /* background: blue; */

}
.statusBar {
    display: flex;
    width: 100%;
    justify-content: center;
}
.statusLabel {
    color: #49D844;
    background-color: rgba(0,0,0,0.3);
    border-radius: 8px 0px 0px 8px;
    padding: 8px;
}
.statusOutput {
    color: #49D844;
    background-color: rgba(0,0,0,0.6);
    border-radius: 0px 8px 8px 0px;
    border: 2px black solid;
    padding: 8px;
}

/* Play Game Field */
.playContainer{
    display: flex;
    width: 100%;
    height: 100%;
}
.playInputForm {
    flex: 7;
    width: 100%;
    height:100%;
}
.playInput {
    width: 100%;
    height: 100%;
    background-color: white;
    border: 3px #A4B3BB solid;
    border-radius: 8px 0 0 8px;
    text-align: center;
    font-size: 24px;
}
.playButton {
    flex: 3;
    /* Display */
    display: flex;
    width: 100%;
    height:100%;
    justify-content: center;
    align-items: center;
    /* Details */
    font-size: 32px;
    color: #C9ECE8;
    font-weight: 800;
    -webkit-text-stroke: 1px black;
    background: #2DAAE8;
    border: 3px #133140 solid;
    border-radius: 0 8px 8px 0;
    cursor: pointer;

}

/* Options Field */
.optionsContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
    width: 100%;
    height: 100%;
    /* Work In Progress */
    /* background: green; */
}
.option {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #3C3F40;
    border: 3px #1A1C1D solid;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 800;
    color: #CCE9E6;
    -webkit-text-stroke: 1px black;
    cursor: pointer;
}

.optionSetting {
    background-color: #6B787F;
    border: 3px #9AAAB2 solid;
}


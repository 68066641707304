.container{
    display: flex;
    width: 100%;
    max-width: 500px;
    margin:auto;
    justify-content: space-between;
    padding-inline: 16px;

}


.imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 4px;
    border: 2px black solid;
    overflow: hidden;
    cursor: pointer;
}
.championImage {
    width: 64px;
    height: 64px;
}



.selectedImageWrapper {
    -webkit-box-shadow:0px 0px 5px 10px rgba(255,242,0,1);
    -moz-box-shadow: 0px 0px 5px 10px rgba(255,242,0,1);
    box-shadow: 0px 0px 5px 10px rgba(255,242,0,1);
    transition: scale 0.02s ease-out
}
.noSelectionImageWrapper {
    transition: scale 0.1s ease-in-out
}
.noSelectionImageWrapper:hover {
    -webkit-box-shadow:0px 0px 5px 10px rgba(255,242,0,1);
    -moz-box-shadow: 0px 0px 5px 10px rgba(255,242,0,1);
    box-shadow: 0px 0px 5px 10px rgba(255,242,0,1);
    scale:1.2;
}

.lockOverlay {
    position:absolute;
    width: 64px;
    height: 64px;
    background-color: rgba(0,0,0,0.2);
    background-image: url('../../../../assets/lock.png');
    background-size:90% 90%;
    background-position: center;
}
@import url('https://fonts.googleapis.com/css2?family=New+Rocker&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.container{
    position: absolute;
    left: 50%;
    bottom: 16px;
    transform: translateX(-50%);
    font-size: 12px;
    font-family: Roboto;
    color: #4B4B4B;
}

.container {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 330px;
    min-height: 400px; 
    padding: 8px;
    pointer-events: none;
}


.LBHeaders {
    display: flex;
    width: 100%;
    font-family: Cousine;
    color: #e7e6e6;
    font-weight: 800;
    margin-bottom: 8px;
}
.LBEntry {
    display: flex;
    width: 100%;
    font-family: Cousine;
}

.entry1 {
    width: 70px;
    margin-bottom: 8px;
    color: #e7e6e6;
}
.entry2 {
    width: 180px;
    font-weight: 800;
}

.container{
    display: flex;
    width: 100%;
    height: 64px;
    background-color: #001A46;
    border-radius: 8px;
    color: #DFFCFF;
    font-size: 24px;
    font-weight: 800;
    font-family: Cousine;
    justify-content: center;
    align-items: center;
    padding-inline: 16px;
    margin-top: 16px;

}
.container {
    background: #477D1D;
    width: 360px;
    height: 64px;
    font-family: 'Cousine';
    -webkit-text-stroke: 1px black;
    font-size: 20px;
    text-shadow: none;
    color: #cdcdcd;
    font-weight: 600;
    margin: 0;
    padding-left: 16px;

    border-radius: 2px;

    border-top: 3px gray solid;
    border-left: 3px rgb(204, 204, 204) solid;
    border-bottom: 3px black solid;
    transition: all 0.3s ease-in;
    cursor: not-allowed;
}
.container:hover {
    width: 440px;
    transition: all 0.5s ease-out;
}

.flex {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.secondFlexItem {
    margin-left:16px;
}

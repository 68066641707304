.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.cooldownCircle {
    position: relative;
    border-radius: 50%;
    width: 0%;
    height: 0%;
    overflow: hidden;
    background: white;
    opacity: 0.5;
}
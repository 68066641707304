.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cooldownCircle {
    position: relative;
    border-radius: 50%;
    width: 91%;
    height: 91%;
    overflow: hidden;
    border: 2px rgba(255,255,255,0.6) solid;
}

.cutout {
    position: absolute;
    top: 50%;
    right: 50%;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    transform-origin: top right;
}

.number {
    position: absolute;
    color: white;
    -webkit-text-stroke: 1.4px black;
    font-weight: 800;
    font-size: 1.5em;
}
.container{

    width: 400px;
    margin-bottom: 16px;
    text-align: center;
    padding-bottom: 16px;
}
.labelText {
    font-size: 60px;
    font-family: "Jersey 10";
    text-shadow: 0px 0px 4px black, 0px 0px 4px black,0px 0px 4px black;
    color:white;
}
.container {
 width: 56px;
 height: 56px;
 position:absolute;
 bottom: 120px;
 pointer-events: none;
 
}

.image {
    border: 3px #3E3E3E solid;
    border-radius: 6px;
    width: 100%;
    height: 100%;
}

.gloss1 {
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 3px #3E3E3E solid;
    /* sharp top white, blurred top white, sharp bottom white, drop shadow */
    box-shadow: inset 0px 2px 1px -0.5px rgba(255, 255, 255, 0.6), inset 0px 8px 4px 0px rgba(255, 255, 255, 0.1), inset 0px -2px 1px -1px rgba(255, 255, 255, 0.2), 0px 1.5px 2px 0px rgba(0, 0, 0, 0.6);
}
.label {
    position: absolute;
    left: 0;
    bottom:0;
    transform: translateX(-50%) translateY(25%);
    /* font-family: Cousine; */
    color: white;
    -webkit-text-stroke: 1.4px black;
    font-weight: 800;
    font-size: 1.1em;
}